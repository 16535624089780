import Calendar from '../Calendar/Calendar';
import GuestPicker from '../GuestPicker/GuestPicker';
import EquipmentTypes from '../EquipmentTypes/EquipmentTypes';
import CategoryTypes from '../CategoryTypes/CategoryTypes';
import { createPortal } from 'react-dom';
import useSWR from 'swr';
import { fetcher } from '../../utils';
import { useState } from 'react';
import { Interweave } from 'interweave';

const SearchForm = () => {


	const [showModal, setShowModal] = useState(false);
	const { data, error, isLoading } = useSWR(
		showModal ? newbookBookByMap.baseUrl + 'wp-json/wp/v2/pages/?slug=site-categories/' : null,
		fetcher,
	);

	if(!error && !isLoading && data !== undefined) {
		console.log(data[0]);
	}

  return (
		<>
			<div className="flex flex-row flex-wrap gap-2 py-4">
			<Calendar />
			<div>
				<GuestPicker />
			</div>
			<div>
				<CategoryTypes />
			</div>
			<div>
				<EquipmentTypes />
			</div>
			<div className='pt-2 text-sm' style={{marginLeft: 'auto'}}>
				<a
					href="javascript:void(0)"
					className='underline'
					onClick={() => setShowModal(true)}
				>Site category description</a>
			</div>
			</div>
			{showModal &&
				createPortal(
					<>
						<div id="default-modal" tabindex="-1" aria-hidden="true" style={{zIndex: '999999', background: '#00000087'}} className="z-50 flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
							<div className="relative p-4 w-full max-w-5xl max-h-full mt-96 md:mt-80">
								<div className="relative bg-white rounded-lg shadow">
									<div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
										{!error && !isLoading && data !== undefined && data.length > 0 &&
											<h3 className="text-xl font-semibold text-gray-900">
												{data[0].title.rendered}
											</h3>
										}
										{isLoading &&
											<h3 className="text-xl font-semibold text-gray-900">Loading...</h3>
										}
										<button type="button" onClick={() => setShowModal(false)} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
											<svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
												<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
											</svg>
											<span className="sr-only">Close modal</span>
										</button>
									</div>
									<div className="p-4 md:p-5 md:pt-0 space-y-4">
										<p className="text-base leading-relaxed">
											{!error && !isLoading && data !== undefined && data.length > 0 &&
												<Interweave
													content={data[0].content.rendered}
													noWrap={true}
												/>
											}
										</p>
									</div>
								</div>
							</div>
						</div>
					</>
					,
					document.getElementById('newbookPortal')
				)
			}
		</>
	);
};

export default SearchForm;
