export const fetcher = ( url: string ) => fetch( url ).then( res => res.json() );

export const truncateString = ( str: string, num: number ) => {
  if ( str.length <= num ) {
    return str;
  }
  return str.slice( 0, num ) + '...';
};

export const isObjectEmpty = ( objectName: {} ) => {
  return objectName && Object.keys( objectName ).length === 0 && objectName.constructor === Object;
};

// Set cookie
export const setCookie = ( name: string, value: string, exdays: number ) => {
  const d = new Date();
  d.setTime( d.getTime() + exdays * 24 * 60 * 60 * 1000 );
  let expires = 'expires=' + d.toUTCString();
  document.cookie = name + '=' + value + ';' + expires + ';path=/';
};

// Get cookie
export const getCookie = ( name: any ) => {
  const value = `; ${ document.cookie }`;
  const parts = value.split( `; ${ name }=` );
  if ( parts.length === 2 ) {
    // @ts-ignore
    return parts.pop().split( ';' ).shift();
  }
};

export const deleteCookie = ( name: string ) => {
  // todo: update below URLs to not be hardcoded
  document.cookie =
    name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=.travelresorts.com;';
  document.cookie =
    name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=.travelresorts.local;';
};

export const buildImageUrls = (
  imageUrl: string,
  width: number,
  height: number,
  region = 'au',
) => {
  if ( imageUrl === undefined ) {
    return 'https://placehold.co/250x250';
  }
  // @ts-ignore
  let baseURL = 'https://drive' + region + '.newbook.cloud/crop_',
    filename = imageUrl.split( '/' );

  return baseURL + width + '_' + height + '/' + filename[ 4 ];
};
