import useSWR from 'swr';
import { fetcher } from '../../utils';
import { useFilterStore, useSiteResultsStore } from '../../store';
import { useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '../Popover/Popover';

const CategoryTypes = () => {
  const { data, error, isLoading } = useSWR(
    // @ts-ignore
    newbookBookByMap.restUrl + 'rest/?v2=true&action=categories_list',
    fetcher,
  );
  const filterStore = useFilterStore( state => state );
  const [ open, setOpen ] = useState( false );
  const categoryTypeChangeHandler = (event: {target: {value: any}}) => {
    const categoryType = isNaN( parseInt( event.target.value ) )
      ? null
      : parseInt( event.target.value );
    filterStore.updateCategoryType( categoryType );
  };
	const currentFilterLabel = !isLoading && filterStore.categoryType !== null && !error ? data.find((element) => element.id === filterStore.categoryType) : 'Category';

  return (
    <>
      <Popover open={ open } onOpenChange={ setOpen }>
        <PopoverTrigger onClick={ () => setOpen( v => ! v ) }>
          <div className="nb-bbm-filter-pill">
						{filterStore.categoryType === null && "All Categories"}
						{currentFilterLabel.name}
					</div>
        </PopoverTrigger>
        <PopoverContent>
          <div className="flex flex-col">
            <label htmlFor="categoryType" className='nb-label'>Category</label>
						<select
							className="nb-select"
							id="categoryType"
							onChange={ () => categoryTypeChangeHandler( event ) }
						>
							<option value="all">All Categories</option>
							{ ! isLoading &&
								! error &&
								data
									.filter( ( site: { id: number } ) => site.id !== 15 )
									.map( ( categoryType: { id: number; name: string } ) => (
										<option
											value={ categoryType.id }
											selected={ filterStore.categoryType === categoryType.id }>
											{ categoryType.name }
										</option>
									) ) }
						</select>
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default CategoryTypes;
