import './App.css';
import ParkMap from './components/ParkMap/ParkMap';
import SearchForm from './components/SearchForm/SearchForm';

function App() {
  return (
    <div>
      <SearchForm />
      <div className="relative" style={ { minHeight: '200px' } }>
        <ParkMap />
      </div>
    </div>
  );
}

export default App;
