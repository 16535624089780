import { useEffect, useRef, useState } from 'react';
import { register } from 'swiper/element/bundle';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
register();
const ImageGallery = ( props: any ) => {
  const data = props.data;
  const categoryTypePlaceholderImage = props.placeholder;
	const [open, setOpen] = useState(false);
  const swiperRef = useRef( null );
  useEffect( () => {
    const swiperContainer = swiperRef.current;
    const params = {
      // These are new...
      injectStyles: [
        `
            :host {
              --swiper-theme-color: #fff;
              --swiper-navigation-size: 20px;
              --swiper-pagination-bullet-horizontal-gap: 3px;
              --swiper-pagination-bullet-size: 6px;
              --swiper-pagination-bullet-inactive-color: white;
              --swiper-pagination-bullet-inactive-opacity: 0.5;
              --swiper-pagination-bottom: 20px;
            }
            .swiper-button-next svg, .swiper-button-prev svg {
              display: none;
            }
            .swiper-button-next, .swiper-button-prev {
              width:10px;
              height:10px;
              transform: rotate(45deg);
              border: 0 solid white;
              border-width:3px 3px 0 0;
              filter: drop-shadow(0 0 5px rgba(0,0,0,0.5))
            }
            .swiper-button-prev {
              transform: rotate(225deg);
            }
            .swiper-pagination {
              border-radius: 100px;
              background: rgba(84, 84, 84, 0.35);
              backdrop-filter: blur(2px);
              padding: 3px;
            }
        `,
      ],
    };

    // @ts-ignore
    Object.assign( swiperContainer, params );
    // @ts-ignore
    swiperContainer.initialize();
  }, [] );

	const lightBoxImageArr = [];
	for(const key in data) {
			lightBoxImageArr.push(
				{
					src: data[key].url,
					alt: data[key].alt,
				}
			);
	}

  return (
    <>
      <swiper-container
        ref={ swiperRef }
        navigation="true"
        pagination="true"
        loop="true"
        init="false"
        pagination-dynamic-bullets="true">
        { data.length > 0 &&
          data.map(
            (
              image: { url: string | undefined; alt: string | undefined },
              index: React.Key | null | undefined,
            ) => (
              <swiper-slide>
                <img
                  key={ index }
                  src={ image.url }
                  alt={ image.alt }
                  title={ image.alt }
                  loading={ 'lazy' }
                  className="h-52 w-full object-cover"
									onClick={() => setOpen(true)}
                  style={ {
                    position: 'relative',
                    zIndex: '1',
                    height: '200px',
                  } }
                />
              </swiper-slide>
            ),
          ) }
        { data.length === 0 && (
          <swiper-slide>
            <img
              src={ categoryTypePlaceholderImage }
              alt={ data.alt }
              loading={ 'lazy' }
              className="h-52 w-full object-cover"
            />
          </swiper-slide>
        ) }
      </swiper-container>
			<Lightbox
				open={open}
				close={() => setOpen(false)}
				slides={lightBoxImageArr}
			/>
    </>
  );
};

export default ImageGallery;
