// Category Filter
export const categoryTypeFilter = ( array: [ any ], filterData: number | null ) => {
  if ( filterData !== null && array !== undefined ) {
    return array.filter(
      ( site: { markerTypeGroupId: number } ) => site.markerTypeGroupId === filterData,
    );
  } else {
    return array;
  }
};

// Category Filter
export const availableOnly = ( array: [ any ] ) => {
	if ( array !== undefined ) {
		return array.filter(
			( marker: { available: boolean } ) => marker.available,
		);
	} else {
		return array;
	}
};
