import { useFilterStore } from '../../store';
import { guestTypes, guestUpdateHandler } from './_config';
import { useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '../Popover/Popover';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GuestPicker = () => {
  const filterStore = useFilterStore( state => state );
  const guests = filterStore.guests;
  const updateGuests = useFilterStore( state => state.updateGuests );
  const [ open, setOpen ] = useState( false );
  const totalGuests =
    filterStore.guests.adults + filterStore.guests.children;
  const guestChangeHandler = (
    guestCount: { adults: number; children: number; infants: number; pets: number },
    guest: 'adults' | 'children' | 'infants' | 'pets',
    type: 'DECREMENT' | 'INCREMENT',
  ) => {
    if ( type === 'INCREMENT' && totalGuests < 6 && guest !== 'pets' ) {
      updateGuests( guestUpdateHandler( guestCount, guest, type ) );
    } else if ( type === 'INCREMENT' && filterStore.guests.pets < 3 && guest === 'pets' ) {
			updateGuests( guestUpdateHandler( guestCount, guest, type ) );
		} else if ( type === 'DECREMENT' ) {
      updateGuests( guestUpdateHandler( guestCount, guest, type ) );
    }
  };

  const guestValue = ( type: string ) => {
    switch ( type ) {
      case 'adults':
        return guests.adults;
      case 'children':
        return guests.children;
      case 'infants':
        return guests.infants;
      case 'pets':
        return guests.pets;
    }
  };

  return (
    <>
      <Popover open={ open } onOpenChange={ setOpen }>
        <PopoverTrigger onClick={ () => setOpen( v => ! v ) }>
          <div className="nb-bbm-filter-pill">
            { totalGuests === 1 ? totalGuests + ' Guest' : totalGuests + ' Guests' }
          </div>
        </PopoverTrigger>
        <PopoverContent>
          { totalGuests > 4 && <div className='notification notification-error'>Daily charge for overnight guests of $10/guest after the first 4 guests. </div> }
          { totalGuests === 6 && <div className='notification notification-error'>Total 6 overnight guests allowed</div> }
					{ filterStore.guests.pets === 3 && <div className='notification notification-error'>Max 3 pets allowed</div> }
          { guestTypes
            .filter( ( site: { active: boolean } ) => site.active )
            .map( ( guestType: any ) => (
              <div key={ guestType.slug } className="flex justify-between gap-4">
                <div className="flex flex-col">
                  <label className='text-base'>{ guestType.label }</label>
                  <span className='text-sm'>{ guestType.subLabel }</span>
                </div>
                <div className="flex flex-row items-center gap-2">
                  <button
                    onClick={ () => guestChangeHandler( guests, guestType.slug, 'DECREMENT' ) }
										className='nb-bbm-button-thin'
                    disabled={
                      guestType.slug === 'adults'
                        ? guests.adults === 1
                        : guestValue( guestType.slug ) === 0
                    }
									>
										<FontAwesomeIcon icon={faMinus} className="h-4 w-4 align-middle" />
                  </button>
                  <div>{ guestValue( guestType.slug ) }</div>
                  <button
                    onClick={ () => guestChangeHandler( guests, guestType.slug, 'INCREMENT' ) }
										className='nb-bbm-button-thin'
									>
										<FontAwesomeIcon icon={faPlus} className="h-4 w-4 align-middle" />
                  </button>
                </div>
              </div>
            ) ) }
        </PopoverContent>
      </Popover>
    </>
  );
};

export default GuestPicker;
