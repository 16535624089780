import useSWR from 'swr';
import { fetcher } from '../../utils';
import { useFilterStore, useSiteResultsStore } from '../../store';
import { useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '../Popover/Popover';

const EquipmentTypes = () => {
  const { data, error, isLoading } = useSWR(
    // @ts-ignore
    newbookBookByMap.restUrl + 'rest/?v2=true&action=equipment_types_list',
    fetcher,
  );
  const [ equipmentType, setEquipmentType ] = useState( null );
  const [ equipmentWidth, setEquipmentWidth ] = useState( null );
  const [ equipmentLength, setEquipmentLength ] = useState( null );
  const [ equipmentYear, setEquipmentYear ] = useState( null );
  const filterStore = useFilterStore( state => state );
  const [ open, setOpen ] = useState( false );
	const currentYear = new Date().getFullYear();
	let equipmentYearLength = equipmentYear !== null ? JSON.stringify(equipmentYear).length : '';
	const [equipmentLabel, setEquipmentLabel] = useState('Equipment');
	const [equipmentLabelFinal, setEquipmentLabelFinal] = useState('Equipment');
	const datesEntered = useSiteResultsStore(state => state.datesEntered);
	const disableApplyButton =
		(equipmentType !== null && equipmentLength !== null && equipmentYear !== null) &&
		((currentYear - equipmentYear) < 10 && equipmentYearLength === 4) &&
		(equipmentLength > 24);

  const equipmentTypeChangeHandler = () => {
    filterStore.updateEquipmentType( equipmentType );
    filterStore.updateEquipmentWidth( equipmentWidth );
    filterStore.updateEquipmentLength( equipmentLength );
    filterStore.updateEquipmentYear( equipmentYear );
		setEquipmentLabelFinal(equipmentLabel);
    setOpen( false );

		localStorage.setItem("equipmentDataType", equipmentType);
		localStorage.setItem("equipmentDataWidth", equipmentWidth);
		localStorage.setItem("equipmentDataLength", equipmentLength);
		localStorage.setItem("equipmentDataYear", equipmentYear);

  };

	const equipmentTypeHandler = (event) => {
		setEquipmentType(
			isNaN(parseInt(event.target.value)) ? null : parseInt(event.target.value),
		)
		setEquipmentLabel(event.target.options[event.target.selectedIndex].text);
	};

  return (
    <>
      <Popover open={ open } onOpenChange={ setOpen }>
        <PopoverTrigger onClick={ () => setOpen( v => ! v ) }>
          <div className="nb-bbm-filter-pill">{equipmentLabelFinal}</div>
        </PopoverTrigger>
        <PopoverContent>
					<div className="flex flex-col gap-2">
						{ equipmentYear !== null && (currentYear - equipmentYear) >= 10 && equipmentYearLength === 4 &&  <div className='notification notification-error'>Please contact the office about rig age for further details.</div> }
						{ equipmentLength !== null && equipmentLength < 24 &&  <div className='notification notification-error'>Rig length is too short. Please contact the office for further details.</div> }
						<div className="flex flex-col">
							<label htmlFor="equipmentType" className="nb-label">Type<span className="text-red-600">*</span></label>
							<select
								id="equipmentType"
								className="nb-select"
								onChange={equipmentTypeHandler}>
								<option value="all">All Equipment Types</option>
								{!isLoading &&
									!error &&
									data.map((EquipmentType: { id: number; name: string }) => (
										<option
											value={EquipmentType.id}
											selected={filterStore.equipmentType === EquipmentType.id}>
											{EquipmentType.name}
										</option>
									))}
							</select>
						</div>
						<div className="flex flex-col">
							<label htmlFor="equipmentWidth" className="nb-label">Width</label>
							<input
								type="number"
								id="equipmentWidth"
								className="nb-input"
								onChange={() =>
									setEquipmentWidth(
										isNaN(parseInt(event.target.value)) ? null : parseInt(event.target.value),
									)
								}
								defaultValue={filterStore.equipmentWidth}
							/>
						</div>
						<div className="flex flex-col">
							<label htmlFor="equipmentLength" className="nb-label">Length<span className="text-red-600">*</span></label>
							<input
								type="number"
								id="equipmentLength"
								className="nb-input"
								onChange={() =>
									setEquipmentLength(
										isNaN(parseInt(event.target.value)) ? null : parseInt(event.target.value),
									)
								}
								defaultValue={filterStore.equipmentLength}
							/>
						</div>
						<div className="flex flex-col">
							<label htmlFor="equipmentYear" className="nb-label">Year<span className='text-red-600'>*</span></label>
							<input
								type="number"
								id="equipmentYear"
								className="nb-input"
								min="1900"
								max="2099"
								onChange={() =>
									setEquipmentYear(
										isNaN(parseInt(event.target.value)) ? null : parseInt(event.target.value),
									)
								}
								defaultValue={filterStore.equipmentYear}
							/>
						</div>
						{ !datesEntered && <div className='notification notification-info'>Enter dates to see equipment availability</div> }
						{ datesEntered && !disableApplyButton && <div className='notification notification-info'>Enter required fields to see equipment availability</div> }
						{
							<button
								className="nb-bbm-button disabled:opacity-70 disabled:cursor-not-allowed"
								onClick={equipmentTypeChangeHandler}
								disabled={!disableApplyButton}
							>
								Apply
							</button>
						}
					</div>
				</PopoverContent>
			</Popover>
		</>
	);
};

export default EquipmentTypes;
