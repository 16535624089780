import { Marker, Popup, useMap } from 'react-leaflet';
import SiteMarkerPopUp from '../SiteMarkerPopUp/SiteMarkerPopUp';
import { categoryTypeFilter } from './_config';
import { useFilterStore, useSiteResultsStore } from '../../store';
import L from 'leaflet';
import { useState } from 'react';

const SiteMarkers = ( props: any ) => {
  const filterStore = useFilterStore( state => state );
	const datesEntered = useSiteResultsStore(state => state.datesEntered);
	// const isMobile = useMediaQuery( { maxWidth: 768 } );
  let results = props.data;
  results = categoryTypeFilter( results, filterStore.categoryType );
	// if(isMobile) {
	// 	results = availableOnly(results);
	// }
  const mapMarkerIcon = ( available: boolean, markerName, displayLabel = false ) => {
    let markerColor;

		if(datesEntered) {
			markerColor = available ? ' nb-park-map-marker-available' : ' nb-park-map-marker-unavailable opacity-40';
		} else {
			markerColor = ' nb-park-map-marker-available';
		}

    return L.divIcon( {
      className: 'marker-svg',
      html:
        '<div>'
				+ '<div style="position: absolute; top: -40px; left: -15px; width: max-content;" class="z-50 bg-white rounded shadow p-2' + (displayLabel ? '' : ' hidden') + '">' +  markerName + '</div>' +
				'<svg class="h-8 w-8 align-middle ' +
        markerColor +
        '" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="currentColor" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg></div>',
      iconSize: [ 36, 36 ],
      iconAnchor: [ 16, 38 ],
    } );
  };
	const map = useMap();
	const mapCenter = map.getCenter();
	const mapZoom = map.getZoom();
	const [siteHovered, setSiteHovered] = useState(null);
	// const [mapCenter, setMapCenter] = useState(map.getCenter());
	// const [mapZoom, setMapZoom] = useState(map.getZoom());

  return results.map(
    ( marker: { id: number | null | undefined; top: number; left: number; available: boolean } ) => (
      <Marker
        key={ marker.id }
        position={ [ marker.top - 12, marker.left + 12 ] }
        icon={ mapMarkerIcon( marker.available, marker.markerTypeName, siteHovered === marker.id ) }
        eventHandlers={ {
					popupclose: e => {
						map.setView(mapCenter, mapZoom)
						// map.getCenter();
						// map.setMaxBounds(map.getBounds());
          },
					mouseover: e => {
						setSiteHovered(marker.id);
					},
					mouseout: e => {
						setSiteHovered(null);
					}
        } }
			>
				<Popup>
					<SiteMarkerPopUp data={marker} available={marker.available} />
				</Popup>
			</Marker>
		),
	);
};

export default SiteMarkers;
