// Sort parks https://bobbyhadz.com/blog/react-sort-array-of-objects
export const sortRates = ( array: any, sortData: any ) => {
  if ( sortData === 'price-low-high' ) {
    //️ sort by Numeric property ASCENDING (1 - 100)
    return [ ...array ].sort( ( a, b ) => a.tariff_total - b.tariff_total );
  } else if ( sortData === 'price-high-low' ) {
    // sort by Numeric property DESCENDING (100 - 1)
    return [ ...array ].sort( ( a, b ) => b.tariff_total - a.tariff_total );
  } else {
    return array;
  }
};

// Removes remove unavailable rates
export const removeUnavailableRates = ( array: any ) => {
  if ( array.length > 0 ) {
    return array.filter( ( rate: { tariff_code: number } ) => rate.tariff_code !== 1 );
  } else {
    return array;
  }
};
