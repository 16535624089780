import { create } from 'zustand';
import { initialResultsTypes } from '../types';

const useSiteResultsStore = create< initialResultsTypes >()( set => ( {
  results: [],
	datesEntered: false,
  updateResults: results => set( () => ( { results: results } ) ),
  updateDatesEntered: datesEntered => set( () => ( { datesEntered: datesEntered } ) ),
} ) );

export default useSiteResultsStore;
