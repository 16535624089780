import { differenceInCalendarDays } from 'date-fns';
import { useFilterStore, useSiteResultsStore } from '../../store';
import useSWR from 'swr';
import { fetcher, truncateString } from '../../utils';
import { removeUnavailableRates, sortRates } from './_config';
import ImageGallery from '../ImageGallery/ImageGallery';
import { Interweave } from 'interweave';
import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const SiteMarkerPopUp = (props: any) => {
	const data = props.data;
	const filterStore = useFilterStore(state => state);
	const numberOfDays = differenceInCalendarDays(
		new Date(filterStore.dates.to),
		new Date(filterStore.dates.from),
	);
	const dateParams =
		'&period_from=' + filterStore.dates.from + '&period_to=' + filterStore.dates.to;
	const guestParams =
		'&adults=' +
		filterStore.guests.adults +
		'&children=' +
		filterStore.guests.children +
		'&infants=' +
		filterStore.guests.infants +
		'&animals=' +
		filterStore.guests.pets;
	const equipmentTypeIdParams =
		filterStore.equipmentType !== null ? '&equipment_type_ids=' + filterStore.equipmentType : '';
	const equipmentWidthParams =
		filterStore.equipmentWidth !== null ? '&equipment_width=' + filterStore.equipmentWidth : '';
	const equipmentLengthParams =
		filterStore.equipmentLength !== null ? '&equipment_length=' + filterStore.equipmentLength : '';
	const {
		data: siteData,
		error: siteError,
		isLoading: siteIsLoading,
	} = useSWR(
		// @ts-ignore
		newbookBookByMap.restUrl +
		'rest/?v2=true&action=sites_get&id=' + data.markerTypeId + '&dataInclude=features',
		fetcher,
	);

	if (!siteError && !siteIsLoading) {
		console.log(siteData.features);
	}

	const {
		data: availabilityData,
		error,
		isLoading,
	} = useSWR(
		// @ts-ignore
		newbookBookByMap.restUrl +
		'rest/?action=bookings_availability_pricing&category_id=' +
		data.markerTypeGroupId +
		'&site_id=' +
		data.markerTypeId +
		dateParams +
		guestParams +
		equipmentTypeIdParams +
		equipmentWidthParams +
		equipmentLengthParams,
		fetcher,
	);
	const showBookingButton = filterStore.equipmentType !== null && filterStore.equipmentLength !== null && filterStore.equipmentYear !== null;

	let rates = [];
	rates =
		availabilityData !== undefined && availabilityData[data.markerTypeGroupId]?.tariffs_available !== undefined
			? sortRates(availabilityData[data.markerTypeGroupId].tariffs_available, 'price-low-high')
			: [];
	rates = removeUnavailableRates(rates);
	const [readMore, setReadMore] = useState(true);
	const siteDescription = readMore ? data.siteDescription : truncateString(data.siteDescription, 170);
	const isMobile = useMediaQuery({ maxWidth: 768 });
	const datesEntered = useSiteResultsStore(state => state.datesEntered);
	const bookNowUrl = (url: string) => {
		return rate?.online_cart_url.replace('bookingsus.newbook.cloud/gulfwatersrvresorttx/index.php', 'gulfwatersrvresorttx.com/book-online/');
	};

	return (
		<div
			className={`rounded flex items-center ${isMobile ? 'flex-col' : 'flex-row'} gap-2 ${isMobile ? 'isMobile' : ''}`}>
			<div className="w-80 items-center">
				<ImageGallery
					data={data.siteImages}
					placeholder={'https://via.placeholder.com/430x430'}
				/>
			</div>
			<div className="p-2">
				<h3 className="text-xl font-bold">{data.markerTypeName}</h3>
				{siteIsLoading &&
					<div style={{minHeight: '140px'}}>Loading site info</div>
				}
				{!siteError && !siteIsLoading && siteData?.features.length > 0 &&
					<>
						<div className='pb-2'><strong>Site Features</strong></div>
						<ul className='grid grid-cols-2 p-0' style={{ paddingBottom: '0', paddingLeft: '0' }}>
							{siteData?.features.map(
								(feature: {
									id: Key | null | undefined;
									name: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined;
									icon: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined;
									count: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined;
								}) => (
									<li
										key={feature.id}
										className='text-xs ml-4 pr-1'
									>
										{feature.name}
									</li>
								),
							)}
						</ul>
					</>
				}
				{!siteError && !siteIsLoading &&
					<Interweave
						content={siteDescription}
						noWrap={true}
					/>
				}
				{data.siteDescription.length > 170 && !siteError && !siteIsLoading &&
					<div
						onClick={() => setReadMore(!readMore)}
						className='cursor-pointer underline'
					>{readMore ? 'Show less' : 'Show More'}</div>
				}
				<div
					className="pt-2 flex flex-col justify-between"
					style={{ minHeight: '42px' }}>
					{isLoading && props.available && datesEntered && <div>Loading Price</div>}
					{!props.available && datesEntered && !isLoading && <div>Unavailable for selected date range</div>}
					{!datesEntered && <div>Enter your arrival & departure dates to see availability</div>}
					{datesEntered && !showBookingButton && <div>Enter your equipment details to book this site</div>}
					{data.available && !isLoading && !error && datesEntered &&
						rates.map((rate: any) => {
							return (<div className="pt-2 flex flex-row justify-between items-center">
								<div>
									<span className="text-xl font-extrabold text-gray-800">${rate?.tariff_total}</span>
									<span className="text-gray-500">/for {numberOfDays}{numberOfDays > 1 ? ' Nights' : ' Night'}</span>
									{rate.tariff_code !== 0 &&
										<div className="text-left text-xs text-gray-500">{rate.tariff_message}</div>
									}
								</div>
								{rate.tariff_code === 0 && showBookingButton &&
									<a
										href={rate?.online_cart_url.replace('bookingsus.newbook.cloud/gulfwatersrvresorttx/index.php', 'gulfwatersrvresorttx.com/book-online/')}
										className="nb-bbm-button" target="_blank">
										Book now
									</a>
								}
							</div>)
						})
					}
				</div>
			</div>
		</div>
	);
};

export default SiteMarkerPopUp;
