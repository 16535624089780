import { create } from 'zustand';
import { initialFilterTypes } from '../types';
import { addDays, format } from 'date-fns';

const queryParameters = new URLSearchParams( window.location.search );
const availableFromParameter = < string >queryParameters.get( 'available_from' );
const availableToParameter = < string >queryParameters.get( 'available_to' );

const useFilterStore = create< initialFilterTypes >()( set => ( {
  dates: {
    from:
      availableFromParameter !== null
        ? availableFromParameter
        : format( addDays( new Date(), 1 ), 'yyyy-MM-dd' ),
    to:
      availableToParameter !== null
        ? availableToParameter
        : format( addDays( new Date(), 2 ), 'yyyy-MM-dd' ),
  },
  guests: {
    adults: 2,
    children: 0,
    infants: 0,
    pets: 0,
  },
  categoryType: null,
  equipmentType: null,
  equipmentLength: 0,
  equipmentWidth: 0,
	equipmentYear: null,
  category: [],
  petFriendly: false,
  updateDates: dates => set( () => ( { dates: dates } ) ),
  updateGuests: guests => set( () => ( { guests: guests } ) ),
  updateCategoryType: categoryType => set( () => ( { categoryType: categoryType } ) ),
  updateEquipmentType: equipmentType => set( () => ( { equipmentType: equipmentType } ) ),
  updateEquipmentLength: equipmentLength => set( () => ( { equipmentLength: equipmentLength } ) ),
  updateEquipmentWidth: equipmentWidth => set( () => ( { equipmentWidth: equipmentWidth } ) ),
	updateEquipmentYear: equipmentYear => set( () => ( { equipmentYear: equipmentYear } ) ),
  updatePetFriendly: () => set( state => ( { petFriendly: ! state.petFriendly } ) ),
} ) );

export default useFilterStore;
