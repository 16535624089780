import { ImageOverlay, MapContainer } from 'react-leaflet';
import L, { LatLngBounds } from 'leaflet';
import useSWR from 'swr';
import { fetcher } from '../../utils';
import { useFilterStore } from '../../store';
import SiteMarkers from '../SiteMarkers/SiteMarkers';
import { useEffect, useState } from 'react';
import Loading from '../Loading/Loading';
const ParkMap = () => {
  const filterStore = useFilterStore( state => state );
  const dateParams = '&periodFrom=' + filterStore.dates.from + '&periodTo=' + filterStore.dates.to;
  const guestParams =
    '&adults=' +
    filterStore.guests.adults +
    '&children=' +
    filterStore.guests.children +
    '&infants=' +
    filterStore.guests.infants +
    '&animals=' +
    filterStore.guests.pets;
  const equipmentTypeIdParams =
    filterStore.equipmentType !== null ? '&equipment_type_ids=' + filterStore.equipmentType : '';
  const equipmentWidthParams =
    filterStore.equipmentWidth !== null ? '&equipment_width=' + filterStore.equipmentWidth : '';
  const equipmentLengthParams =
    filterStore.equipmentLength !== null ? '&equipment_length=' + filterStore.equipmentLength : '';
  const {
    data: mapData,
    error: mapError,
    isLoading: mapIsLoading,
  } = useSWR(
    // @ts-ignore
    newbookBookByMap.restUrl +
      'rest/?v2=true&action=sites_maps&dataInclude=images' +
      dateParams +
      guestParams,
    fetcher,
  );
  const {
    data: availabilityData,
    error: availabilityError,
    isLoading: availabilityIsLoading,
  } = useSWR(
    // @ts-ignore
    newbookBookByMap.restUrl +
      'rest/?action=bookings_availability_pricing' +
      '&period_from=' +
      filterStore.dates.from +
      '&period_to=' +
      filterStore.dates.to +
      guestParams +
      equipmentTypeIdParams +
      equipmentWidthParams +
      equipmentLengthParams,
    fetcher,
  );
  const [ parkMapHeight, setParkMapHeight ] = useState( null );
  const [ parkMapWidth, setParkMapWidth ] = useState( null );
  const [ parkMapImageUrl, setParkMapImageUrl ] = useState(
    'https://placehold.co/1000x1000?text=Loading+Map',
  );
  const [ siteMarkers, setSiteMarkers ] = useState( [] );
  const [ availabilitySiteMarkers, setAvailabilitySiteMarkers ] = useState( [] );
  const bounds = [
    [ 0, 0 ],
    [ parkMapHeight, parkMapWidth ],
  ];
  let style = { height: parkMapHeight + 'px', width: parkMapWidth + 'px', maxWidth: '100%' };
  let minMapZoom = -4;
  const markerArr:
    | ( ( prevState: never[] ) => never[] )
    | {
        id: any;
        siteId: any;
        available: any;
        categoryDescription: any;
        left: any;
        mapId: any;
        markerType: any;
        markerTypeGroupId: any;
        markerTypeGroupName: any;
        markerTypeId: any;
        markerTypeName: any;
        siteDescription: any;
        siteImages: any;
        top: any;
      }[] = [];
  const somethingIsLoading =
    ( parkMapHeight === null && parkMapWidth === null ) || availabilityIsLoading || mapIsLoading;

  useEffect( () => {
    if ( ! mapIsLoading && ! mapError ) {
      setParkMapImageUrl( mapData[ 0 ].mapImageUrl );
      setParkMapHeight( mapData[ 0 ].imageHeight );
      setParkMapWidth( mapData[ 0 ].imageWidth );
      setSiteMarkers( mapData[ 0 ].markers );
    }
  }, [ JSON.stringify( mapData ) ] );

  useEffect( () => {
    if ( ! mapIsLoading && ! mapError && ! availabilityError && ! availabilityIsLoading ) {
			// console.log(mapData);
			// console.log(availabilityData);
      for ( const siteDataKey in siteMarkers ) {
				// 211 = 10501
				// 415 = 10508
				// 740 = 10626
				// 742 = 10627
				if(![10501, 10508, 10626, 10627].includes(siteMarkers[ siteDataKey ].id)) {
					markerArr.push({
						id: siteMarkers[siteDataKey].id,
						siteId: siteMarkers[siteDataKey].id,
						available:
							availabilityData[siteMarkers[siteDataKey].markerTypeGroupId]?.sites_code === 0 &&
							siteMarkers[siteDataKey].available,
						categoryDescription: siteMarkers[siteDataKey].categoryDescription,
						left: siteMarkers[siteDataKey].left,
						mapId: siteMarkers[siteDataKey].mapId,
						markerType: siteMarkers[siteDataKey].markerType,
						markerTypeGroupId: siteMarkers[siteDataKey].markerTypeGroupId,
						markerTypeGroupName: siteMarkers[siteDataKey].markerTypeGroupName,
						markerTypeId: siteMarkers[siteDataKey].markerTypeId,
						markerTypeName: siteMarkers[siteDataKey].markerTypeName,
						siteDescription: siteMarkers[siteDataKey].siteDescription,
						siteImages: siteMarkers[siteDataKey].siteImages,
						top: siteMarkers[siteDataKey].top,
					});
				}
      }
      // @ts-ignore
			setAvailabilitySiteMarkers( markerArr );
    }
  }, [ JSON.stringify( siteMarkers ), JSON.stringify( availabilityData ) ] );

  return (
    <>
      { somethingIsLoading && <Loading /> }
      { parkMapHeight !== null && parkMapWidth !== null && (
        <MapContainer
          crs={ L.CRS.Simple }
          attributionControl={ false }
          minZoom={ minMapZoom }
          bounds={ bounds }
					// maxBounds={bounds}
          style={ style }
          scrollWheelZoom={ false }
          zoomSnap={ 0 }
          zoomDelta={ 0.25 }
				>
          <ImageOverlay bounds={ bounds } url={ parkMapImageUrl } />
          <SiteMarkers data={ availabilitySiteMarkers } />
        </MapContainer>
      ) }
    </>
  );
};

export default ParkMap;
