import React, { useEffect, useState } from 'react';
import './Calendar.css';
import { format, isBefore, isValid, parseISO } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { useMediaQuery } from 'react-responsive';
import { useFilterStore, useSiteResultsStore } from '../../store';
import { Popover, PopoverContent, PopoverTrigger } from '../Popover/Popover';

const Calendar = () => {
  interface dateRangeTypes {
    from: Date | null;
    to: Date | null;
  }
  const filterStore = useFilterStore( state => state );
  const today: Date = new Date();
  const [ dateRange, setDateRange ] = useState< dateRangeTypes >( {
    from: parseISO( filterStore.dates.from ),
    to: parseISO( filterStore.dates.to ),
  } );
  let calendarsToShow = 2;
  const isMobile = useMediaQuery( { maxWidth: 600 } );
  const [ defaultMonth, setDefaultMonth ] = useState( today );
  const updateDates = useFilterStore( state => state.updateDates );
  const [ open, setOpen ] = useState( false );
	const [datesLabel, setDatesLabel] = useState('Enter Arrival & Departure Dates');
	const datesEntered = useSiteResultsStore(state => state.datesEntered);
	const setDatesEntered = useSiteResultsStore(state => state.updateDatesEntered);
	const [firstDatePicked, setFirstDatePicked] = useState(false);

	useEffect(() => {
		if(datesEntered) {
			setDatesLabel(format(parseISO(filterStore.dates.from), 'd LLL y') + ' - ' + format(parseISO(filterStore.dates.to), 'd LLL y'));
		}
	}, [JSON.stringify(dateRange)]);

  if ( isMobile ) {
    calendarsToShow = 1;
  }

  const calendarHandler = ( DateRange: any ) => {
    if ( isValid( DateRange.from ) && isValid( DateRange.to ) ) {
      setDateRange( DateRange );
      setDefaultMonth( new Date( DateRange.to ) );
      updateDates( {
        from: format( DateRange.from, 'yyyy-MM-dd' ),
        to: format( DateRange.to, 'yyyy-MM-dd' ),
      } );
    }
  };

  const isSelectingFirstDay = ( from: number | Date, to: Date, day: number | Date ) => {
    const isBeforeFirstDay = from && isBefore( day, from );
    const isRangeSelected = from && to;
    return ! from || isBeforeFirstDay || isRangeSelected;
  };

  const handleDayClick = ( day: number | Date | React.SetStateAction< null > ) => {
    const { from, to } = dateRange;
    // @ts-ignore
    if ( from && to && day >= from && day <= to ) {
      handleResetClick();
      return;
    }
    // @ts-ignore
    if ( isSelectingFirstDay( from, to, day ) ) {
			setFirstDatePicked(true);
      setDateRange( {
        // @ts-ignore
        from: day,
        to: null,
      } );
      // setEnteredTo(null);
    } else {
      setDateRange( {
        from: from,
        // @ts-ignore
        to: day,
      } );
      // setEnteredTo(day);
      calendarHandler( {
        from: from,
        to: day,
      } );
      setOpen( false );
			setDatesEntered(true);
    }
  };

  const handleResetClick = () => {
    setDateRange( {
      from: null,
      to: null,
    } );
    // setEnteredTo(null);
  };

  const mouseEnterHandler = ( day: number | Date | React.SetStateAction< null > ) => {
    const { from, to } = dateRange;
    // @ts-ignore
    if ( ! isSelectingFirstDay( from, to, day ) ) {
      setDateRange( {
        from: from,
        to: to,
      } );
      // setEnteredTo(day);
    }
  };

  return (
    <>
      <Popover open={ open } onOpenChange={ setOpen }>
        <PopoverTrigger onClick={ () => setOpen( v => ! v ) }>
          <div className="nb-bbm-filter-pill">
						{datesLabel}
          </div>
        </PopoverTrigger>
        <PopoverContent calendar={ true }>
          <DayPicker
            id="datePicker"
            mode="range"
            className={ `
											${ isMobile ? 'rdp-calendar-mobile' : 'rdp-calendar-desktop' }
										` }
            showOutsideDays
            numberOfMonths={ calendarsToShow }
            selected={ !firstDatePicked ? { from: null, to: null } : dateRange }
            disabled={ { before: today } }
            onDayMouseEnter={ mouseEnterHandler }
            onDayClick={ handleDayClick }
            defaultMonth={ defaultMonth }
          />
        </PopoverContent>
      </Popover>
    </>
  );
};

export default Calendar;
