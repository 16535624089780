export const guestTypes = [
  {
    label: 'Adults',
    slug: 'adults',
    subLabel: '18 years and older',
    icon: '',
    active: true,
  },
  {
    label: 'Children',
    slug: 'children',
    subLabel: '0 - 17 years old',
    icon: '',
    active: true,
  },
  {
    label: 'Infants',
    slug: 'infants',
    subLabel: 'Under 3 years old',
    icon: '',
    active: false,
  },
  {
    label: 'Pets',
    slug: 'pets',
    subLabel: 'Breed restrictions apply',
    icon: '',
    active: true,
  },
];

export const guestUpdateHandler = (
  guestCount: { adults: number; children: number; infants: number; pets: number },
  guest: 'adults' | 'children' | 'infants' | 'pets',
  type: 'DECREMENT' | 'INCREMENT',
) => {
  switch ( guest ) {
    case 'adults':
      if ( type === 'INCREMENT' && guestCount.adults < 10 ) {
        return {
          adults: guestCount.adults + 1,
          children: guestCount.children,
          infants: guestCount.infants,
          pets: guestCount.pets,
        };
      } else if ( type === 'DECREMENT' && guestCount.adults > 1 ) {
        return {
          adults: guestCount.adults - 1,
          children: guestCount.children,
          infants: guestCount.infants,
          pets: guestCount.pets,
        };
      } else {
        return guestCount;
      }
    case 'children':
      if ( type === 'INCREMENT' && guestCount.children < 10 ) {
        return {
          adults: guestCount.adults,
          children: guestCount.children + 1,
          infants: guestCount.infants,
          pets: guestCount.pets,
        };
      } else if ( type === 'DECREMENT' && guestCount.children > 0 ) {
        return {
          adults: guestCount.adults,
          children: guestCount.children - 1,
          infants: guestCount.infants,
          pets: guestCount.pets,
        };
      } else {
        return guestCount;
      }
    case 'infants':
      if ( type === 'INCREMENT' && guestCount.infants < 10 ) {
        return {
          adults: guestCount.adults,
          children: guestCount.children,
          infants: guestCount.infants + 1,
          pets: guestCount.pets,
        };
      } else if ( type === 'DECREMENT' && guestCount.infants > 0 ) {
        return {
          adults: guestCount.adults,
          children: guestCount.children,
          infants: guestCount.infants - 1,
          pets: guestCount.pets,
        };
      } else {
        return guestCount;
      }
    case 'pets':
      if ( type === 'INCREMENT' && guestCount.pets < 3 ) {
        return {
          adults: guestCount.adults,
          children: guestCount.children,
          infants: guestCount.infants,
          pets: guestCount.pets + 1,
        };
      } else if ( type === 'DECREMENT' && guestCount.pets > 0 ) {
        return {
          adults: guestCount.adults,
          children: guestCount.children,
          infants: guestCount.infants,
          pets: guestCount.pets - 1,
        };
      } else {
        return guestCount;
      }
  }
};
